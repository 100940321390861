$primary-color:#f15a3b;
$secundary-color:#b7452e;
@import 'subbrand-header';
@import 'jquery-ui';
@import 'datatables';
@import 'datepicker';
@import 'opentip';
@import "login-message-overlay";
/*==================================================
 * Defaults
 * ===============================================*/
html,body{height:100%;}
body{font-family:'Lato', sans-serif;font-size:16px;color:#000;line-height:22px;text-rendering: optimizeLegibility;padding:0;margin:0;}
h1{font-size:36px;font-weight:400;line-height:36px;}
h2{font-size:22px;font-weight:700;font-style:normal;margin:0;line-height:30px;}
h3{font-size:16px;margin:0;padding:0;font-weight:700;line-height:30px;}
h4{font-size:14px;margin:0;padding:0;font-weight:700;}

.align-right {float:right;padding-left:50px;padding-bottom:23px;}
.align-left {float:left;padding-right:50px;padding-bottom:23px;}
.bold {font-weight:bold;}
.button, button{cursor:pointer;display:inline-block;font-size:14px;line-height:38px;color:#fff;padding:0 30px;background-color:$primary-color;border:none;border-bottom:2px solid $secundary-color;border-color:$secundary-color;/* stupid ie9 fix */white-space:nowrap;}
.button[disabled], button[disabled] {cursor:default}
.button-small {line-height:28px;padding:0 20px;}
.button.grey, button.grey, button[disabled] {background-color:#ebebeb!important;border-color:#BFBFBF!important;color:#000;}
.button.grey .fa, button.grey .fa, button[disabled] .fa {color:#000;}
.button:hover, button:hover{box-shadow:inset 0 0 100px rgba(255,255,255,0.2)}
.button.loading:after, button.loading:after {background-position:20px center;background-color:transparent;}
.button.loading .fa, button.loading .fa {visibility:hidden;}
.no-rgba .button:hover, .no-rgba button:hover {background-image:url(/img/ie/white-20.png);}
button .fa,
.button .fa {color:#fff;}
.button .fa-angle-right, button .fa-angle-right {padding-left:5px;float:none!important;display:inline!important;}
#main-wrapper {
    .styled-select{
        overflow:hidden;width:216px;line-height:38px;background-color:$primary-color;color:#fff;position:relative;display:inline-block;vertical-align:middle;border-bottom: 2px solid $secundary-color;
        &:hover {box-shadow:inset 0 0 100px rgba(255,255,255,0.2)}
        &.multiple-select {
            overflow:visible;
            .ms-parent {
                background-color:#f15a3b;border:0;
                .ms-choice {
                    color:#fff;line-height:38px;height:38px;
                    > div {
                        top:10px;right:9px;font:normal normal normal 20px/1 FontAwesome;background:none;
                        &:before {content:"\f107"}
                    }
                    > span {
                        padding-left:20px;font-family:'Lato', sans-serif;
                        &.placeholder {color:#fff;}
                    }
                }
                .ms-drop {
                    ul {
                        text-align:left;
                        li {
                            padding-left:0;font-size:14px;
                        }
                    }
                }
            }
        }
    }
}
.no-rgba #main-wrapper .styled-select:hover {background-image:url(/img/ie/white-20.png);}
#main-wrapper .styled-select select {position:relative;color:#fff;-webkit-appearance:none;font-size:14px;padding:0 0 0 20px;background-color:transparent;border:0;border-radius:0;width:100%;cursor:pointer;line-height:40px;height:40px;}
#main-wrapper ol, #main-wrapper ul{}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    #main-wrapper .styled-select:before{display:inline-block;font: normal normal normal 14px/1 FontAwesome;font-size:20px;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;content: "\f107";position:absolute;right:16px;top:12px;}
}
#main-wrapper .styled-select select option{color:#000;background-color:#fff;}
input[disabled]{color:#b4b4b4;}
input,textarea,select{border:1px solid #d4d4d4;font-family:'Lato', sans-serif;}
strong{font-weight:700;}
p{font-size:16px;margin:0;margin-bottom:23px;text-rendering:optimizeLegibility;}
p:empty{display:none}
p.intro{font-size:16px;}
p.excerpt{font-size:16px;line-height:24px;font-weight:400;margin-bottom:22px;}
p.excerpt-lg{width:730px;text-align:center;margin:auto;font-size:20px;line-height:36px;}
video {width:100%;max-width:585px;height:auto;}
img {max-width:100%;height:auto;}
hr {border:0;border-bottom:1px solid #d4d4d4;margin:30px 0;}
a{text-decoration:none;color:$primary-color;}
a[href^=tel] {pointer-events:none;}
@media only screen and (max-width:767px) {
    h2{font-size:26px}
    p.excerpt-lg{width:100%;}
    a[href^=tel] {pointer-events:auto;}
    .align-left,
    .align-right {float:none;padding-left:0;padding-right:0;}
}

/*==================================================
 * General styles
 * ===============================================*/
.greybg {background-color:#ebebeb;}
.icon-wrapper .icon{position:relative;cursor:help;}
.tooltip-content {display:none;position:absolute;top:-45px;right:-31px;-webkit-filter: drop-shadow(-2px 2px 2px rgba(0,0,0,0.3));}
.tooltip-content:before{content:"";border-top:7px solid #ebebeb;border-right:15px solid transparent;border-left:15px solid transparent;width:0px;position:absolute;bottom:-7px;left:0;right:0;margin:auto;}
.tooltip-content p{padding:5px 16px 3px 14px;margin:0;color:#000;white-space:nowrap;font-weight:400;background:#ebebeb;}
.icon-wrapper .icon:hover > .tooltip-content {display:block;} 
@media only screen and (max-width:1023px) {
	p{font-size:18px;line-height:24px}
}

/*==================================================
 * All-knowing wrapper
 * ===============================================*/
.container{position:relative;width:100%;max-width:960px;margin:auto;padding:0 24px;box-sizing:border-box;}

#main-wrapper {
    .col-3{
        position:relative;width:292px;float:left;margin-left:18px;
        &:first-child {margin:0;}
    }
}

@media only screen and (max-width:1023px) {
    #main-wrapper .col-3{position:relative;width:222px;float:left;margin-left:18px;}
    #main-wrapper .col-3:first-child{margin:0;}
}

#main-wrapper {
    .item{
        display:block;height:165px;position:relative;padding:25px;overflow:hidden;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box
        * {color:#fff;}
        p{font-size:16px;line-height:18px;color:#fff;font-weight:400;margin:0 0 10px 0;}
        p.readmore{font-size:14px;font-weight:400;position:absolute;bottom:10px;left:25px;color:#fff;text-decoration:none;}
        p.readmore .fa {padding-left:5px;}
        h2{color:#fff;font-size:20px;font-weight:700;padding:0;margin:0 0 4px;line-height:30px;white-space:nowrap;}
        > span{position:absolute;bottom:-10px;right:-10px;color:#fff;opacity:0.3;font-size:100px;}
    
        &.oranje{background:#d38629;background:linear-gradient(-135deg, transparent 20px, #d38629 20px, #d38629);}
        &.blauw{background-color:#2870b4;background:linear-gradient(-135deg, transparent 20px, #2870b4 20px, #2870b4);}
        &.lichtblauw{background:#2c82c9;background:linear-gradient(-135deg, transparent 20px, #2c82c9 20px, #2c82c9);}
        &.paars{background-color:#9365b8;background:linear-gradient(-135deg, transparent 20px, #9365b8 20px, #9365b8);}
        &.lichtrood{background-color:#e14938;background:linear-gradient(-135deg, transparent 20px, #e14938 20px, #e14938);}
        &.rood{background-color:#cd1f49;background:linear-gradient(-135deg, transparent 20px, #cd1f49 20px, #cd1f49);}
        &.groen{background-color:#508c23;background:linear-gradient(-135deg, transparent 20px, #508c23 20px, #508c23);}
    }
}

@media only screen and (max-width:1023px) {
    #main-wrapper .item{min-height:180px;max-width:292px;}
    #main-wrapper .item p{padding:0;}
}

@media only screen and (max-width:767px) {
    #main-wrapper .item {margin:0 auto;}
    #main-wrapper .item > h2{font-size:20px;}
}

/*==================================================
 * Header
 * ===============================================*/
header.main{
    position:relative;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;background:#fff url(/img/background.jpg);
    .pre-header{
        width:100%;background-color:#565656;color:#fff;line-height:26px;
        p{margin:0;font-size:12px;}
        span{font-size:18px;line-height:26px;}
        .login{margin-left:25px;}
    }
    .header{height:120px;}
    .logo{
        display:block;float:left;margin-left:-35px;border:0;outline:0;
        > img{padding-left:65px;padding-top: 29px;height:auto;max-width:185px;width:100%;border:0;outline:0;-webkit-backface-visibility:hidden;}
        > video{position:absolute;top:-30px;height:auto;max-width:320px;width:100%;border:0;outline:0;-webkit-backface-visibility:hidden;}
    }
    .contact{
        float:right;text-align:right;margin-top:37px;
        p{line-height:12px;margin:0;padding:0;padding-bottom:13px;}
        .fa{color:$primary-color;font-size:24px;padding-right:15px;float:left;}
        .number{color:#000;text-decoration:none;line-height:22px;margin:0;padding:0;font-size:24px;font-weight:700;float:left;}
    }
}
@media only screen and (max-width:1023px) {
	header.main .logo{max-width:100%;height:auto;}
}

/*==================================================
 * Page title + Breadcrumbs
 * ===============================================*/
section.page-title {
    background-image:url(/img/slider-bg.png);background-size:cover;padding:40px 0;margin-bottom:40px;
    h1{
        margin:0;line-height:1.4;
        em {color:#f15a3b;font-style:normal;}
    }
    .breadcrumbs-wrapper {
        position:relative;font-size:14px;
        p{display:block;position:absolute;top:0;left:0;font-weight:400;}
        nav{display:inline;}
        a{text-decoration:none;color:#000;padding-left:4px;font-weight:bold;color:$primary-color;}
        .breadcrumbs {
            display:block;padding-left:90px;margin-top:1em;
            ol{padding-left:0;margin:0;font-size:16px;}
            ol li{display:inline-block;padding-right:10px;}
            ol li:first-child:before{content:"";}
            ol li:before{content:"- ";letter-spacing:4px;}
        } 
    }
}
@media only screen and (max-width:1023px) {
    section.page-title .breadcrumbs-wrapper .breadcrumbs {padding-left:100px;}
    section.page-title .breadcrumbs-wrapper .breadcrumbs ol {padding-top:3px;}
}
@media only screen and (max-width:767px) {
    section.page-title{padding:20px 0;}
    section.page-title h1{line-height:normal;margin-bottom:0;float:none;width:100%;}
    section.page-title .breadcrumbs-wrapper{display:none;float:none;font-size:11px;}
    section.page-title .breadcrumbs-wrapper p{line-height:normal;}
    section.page-title .breadcrumbs-wrapper nav{line-height:20px;}
}

/*==================================================
 * Navigation
 * ===============================================*/
.desktop-nav{
    position:relative;min-height:60px;
    .menu-btn {@extend .clearfix;display:none;}
    
    nav.main{
        width:100%;background-color:rgba(235,235,235,0.95);color:#fff;font-size:16px;-box-sizing:border-box;
        &:before {content:"";position:absolute;top:0;left:0;width:100%;height:57px;background-color:$primary-color;border-bottom:3px solid $secundary-color;}
        &.fixed {position:fixed;top:0;}
        ol{
            white-space:nowrap;
            @extend .clearfix;padding:0;font-size:16px;float:left;margin:0;
            > li{
                display:inline-block;position:relative;
                > a{
                    text-decoration:none;color:#fff;line-height:57px;display:block;padding-left:15px;padding-right:15px;width:100%;box-sizing:border-box;
                    > span {display:block;white-space:nowrap;}
                }
            }
            > li:hover, li.menuactive {box-shadow:inset 0 0 100px rgba(255,255,255,0.2);}
            ol {
                margin:25px 0;border-left:1px solid #c7c7c7;display:none;-webkit-box-sizing:border-box;box-sizing:border-box;list-style:none;
                > li {
                    display:block;margin-left:0;
                    &:hover,
                    &.menuactive {box-shadow:none;}
                }
                > li > a {color:#000;font-size:14px;line-height:24px;padding-left:15px;white-space:nowrap;box-shadow:none!important;background:none!important;}
            }
            li.active ol {display:block;position:relative;}
        }
        .search {
            @extend .clearfix;
            float:right;font-size:30px;padding:5px 0px 5px 5px;line-height:18px;margin-top:2px;
            form{padding:0;border:none;margin:0;}
            input{background:transparent;border:none;outline:0;font-size:16px;color:#fff;padding:0 5px;vertical-align:middle;width:150px;height:44px;line-height:44px;}
            button{
                position:relative;background:none;left:-7px;top:-2px;border:none;font-size:26px;outline-color:#3a69ab;padding:0 0 0 10px;
                span{font-size:20px;}
            }
            button:hover {box-shadow:none;}
        }
        .search.hide{display:none;}
        .search.active form{
            border:1px solid #fff;
            ::-webkit-input-placeholder{color:#fff;}
            button {top:-3px;left:-6px;}
        }
        > .container > a{
            float:right;color:#fff;
            > span{font-size:20px;padding:0 10px;display:block;line-height:57px;}
        }
        a.login {position:relative;line-height:57px;background-color:#03365f;float:right;padding:0 15px;}
        a.login:after {position:absolute;bottom:-3px;left:0;width:100%;height:3px;content:"";background-color:#022440;}
    }
    
    nav.sub{
        display:none;position:absolute;top:100%;width:100%;z-index:99;left:0;background:#ebebeb;margin:0;
        ol.submenu{
            width:100%;margin:25px 0;padding-left:15px;border-left:1px solid #c7c7c7;
            li{list-style:none;float:none;margin-left:0;}
        }
    }
    nav.sub.active{display:block;}
}


.no-rgba .desktop-nav nav.main ul > li > a:hover,
.no-rgba .desktop-nav nav.main ul > li > a.menuactive {background-image:url(/img/ie/white-20.png);}

.desktop-nav .logout {display: none;}

@media only screen and (max-width:959px) {
    .desktop-nav {min-height:0;}
    .desktop-nav .container {width:100%;padding:0;}
    .desktop-nav .menu-btn {float:left;}
    .desktop-nav .logout {display: block; float:right;}
    .desktop-nav .logout>a {line-height: 57px; color: white; margin-right: 25px;}
    .desktop-nav .container>a,
    .desktop-nav .menu-btn {cursor:pointer;display:block;color:#fff;height:60px;font-size:22px;margin:0;padding:0 0 0 24px;}
    .desktop-nav .menu-btn > span{margin-right:10px;line-height:57px;}
    .desktop-nav .menu-btn > span:before{padding-right:10px;}
    .desktop-nav nav.main {position:static;}
    .desktop-nav nav.main ol {width:100%;padding:24px 0;float:none;display:none;clear:both;}
    .desktop-nav nav.main ol.expand {display:block;}
    .desktop-nav nav.main ol li {font-size:18px;line-height:32px;float:none;margin:0;width:auto!important;display:block;}
    .desktop-nav nav.main ol li a {color:$primary-color;padding:0 24px;line-height:36px;}
    .desktop-nav nav.main ol ol {display:block!important;width:auto!important;margin:0 0 0 24px;padding:0;}
    .desktop-nav nav.main ol ol li a {overflow:hidden;text-overflow:ellipsis;line-height:26px;}
}

@media only screen and (max-width:767px) {
    #main-wrapper > header .contact{display:none;}
}

/*==================================================
 * Icon navigation
 * ===============================================*/
nav.icons {
    margin:50px 0;
    ol {
        @extend .clearfix;list-style:none;margin:0;padding:0;overflow:hidden;
        li {
            display:block;float:left;margin:0 24px 24px 0;background-color:#ebebeb;
            a {
                display:block;width:210px;height:210px;box-sizing:border-box;border:1px solid #d5d5d5;text-align:center;color:#000;position:relative;
                .fa {font-size:60px;display:block;margin-top:48px;}
                .title, 
                .title p {display:block;margin-top:30px;padding:0 5px;}
                .pill-counter {position:absolute;top:10px;right:10px;background-color:$primary-color;border-radius:100000000000000px;font-weight:bold;color:white;padding:3px;font-size:75%;width:100%;height:100%;max-width:10%;max-height:10%;}
            }
            &:hover {
                background-color:#f15a3b;
                .orange,
                a {
                    color:#fff;
                    .pill-counter {background-color:white;color:black;}
                }
            }
        }
        li:nth-child(4n+0) {margin-right:0;}
    }
}

@media only screen and (max-width:1023px) {
	nav.icons {
		ol {
			li {
				a {
					width:160px;height:160px;
					.fa {font-size:40px;margin-top:28px;}
					.title,
                    .title p {margin-top:14px;font-size:14px;}
				}
			}
		}
	}
}

@media only screen and (max-width:767px) {
	nav.icons {
		ol {
			li {
				margin-right:0;
				&:nth-child(2n+0) {float:right;}
                &:nth-child(2n+1) {clear:both;}
				a {
                    width:150px;height:150px;
                }
			}
		}
	}
}

@media only screen and (max-width:374px) {
    nav.icons {
        ol {
            li {
                a {
                    width:125px;height:125px;
                    .fa {margin-top:24px;font-size:30px;}
                    .title,
                    .title p {line-height:16px;margin-top:11px;}
                }
            }
        }
    }
}

/*==================================================
 * Content
 * ===============================================*/
#main-wrapper {
    .container {
        > .content{
            position:relative;width:585px;float:left;min-height:1px;z-index:2;
        }
    }
    > *:last-child {padding-bottom:40px;}
}

.youtube-container {margin-bottom:23px;}
.news-summary .youtube-container iframe {width:525px;height:295px;}
@media only screen and (max-width:648px) {
    .news-summary .youtube-container iframe {width:272px;height:153px;}
}
@media only screen and (max-width:585px) {
    .youtube-container iframe {width:272px;height:153px;}
}


@media only screen and (max-width:1023px) {
    #main-wrapper .container > .content {width:100%;float:none;}

    #main-wrapper .container .col-1{position:relative;width:100%;float:none;}
    #main-wrapper .container .col-2{position:relative;width:100%;margin:0;float:none;}
    #main-wrapper .container .col-2:first-child{margin:0;}
    #main-wrapper .container .col-3{position:relative;width:222px;float:left;margin-left:18px;}
    #main-wrapper .container .col-3:first-child{margin:0;}
    
    #main-wrapper .col-2 {margin-left:0;width:100%;}
}

/*==================================================
 * Aside
 * ===============================================*/
aside {
    position:relative;width:296px;margin-left:21px;padding-left:10px;float:left;
    &.fixed{position:fixed;z-index:1;top:100px;left:0;right:0;margin:auto;padding-left:616px;}
    &.centered {text-align:center;}
    h2 {margin-bottom:15px;}
    > p{text-align:right;}
    > div{margin-bottom:30px;}
    img{position:relative;border:1px solid #d4d4d4;margin-bottom:30px;}
    .tabs{
        .nav-tabs li{width:50%;text-align:center;}
        .nav-tabs li.active > a{background-color:#f7f7f7;border-bottom-color:transparent;}
        .tabs-content{padding:24px 22px 21px 22px;background-color:#f7f7f7;border:1px solid #d4d4d4;margin:-1px 0 0 0;}
        .tabs-content .inner > p{line-height:25px;}
    }
    .call-to-action{
        position:relative;border:1px solid #d4d4d4;padding:22px 33px 18px 23px;margin-bottom:30px;
        h3{font-size:26px;margin-bottom:23px;}
        p{margin-bottom:14px;}
        strong{font-size:18px;color:$primary-color;font-weight:700;}
        .button,
        button{float:right;}
        input,
        select{width:100% !important;border:1px solid #d4d4d4;margin-bottom:14px;line-height:29px;height:29px;box-sizing:border-box;padding:0px 5px;}
    }
    form{padding:0;border:none;margin:0;}
    .shareaholic-canvas {height:75px;}
    .shareaholic-share-buttons-container ul.shareaholic-share-buttons{margin-left:-5px!important;}
}


@media only screen and (max-width:1023px) {
    #main-wrapper .container aside{margin:0;padding:0;width:100%;float:none;}
    #main-wrapper .container aside.fixed {position:static;padding-left:0;}
    #main-wrapper .container aside > p {text-align:left;}
    .container.contact aside .contact-details{margin:0;}
    .container.contact aside .contact-details > ul > li{width:250px;display:inline-block;vertical-align:top;}
    #main-wrapper .container aside > img{max-width:100%;height:auto;margin-top:23px}
    #main-wrapper .container aside > .tabs .tabs-content{margin-bottom:27px;}
}
/*==================================================
 * Footer
 * ===============================================*/
body>footer {
    position:relative;height:323px;clear:both;
    p{margin:0;}
    a{color:#fff;text-decoration:none;}
    .container{
        .col-3{position:relative;width:292px;float:left;margin-left:18px;}
        .col-3:first-child{margin:0;}
    } 

    // flex grid for footer

    .container-flex {
        display:flex;
        flex-wrap: wrap;
        > * {
            flex:1 0 100%;
            width:auto;
            float:none;
            margin-bottom:20px;
        }
    }

    @media (min-width:768px){
        .container-flex {
            > * {
                flex:1 0 33.3333%;
            }
        }
    }
    
    .contact {
        background-color:#565656;padding:31px 0 58px 0;color:#fff;
        header{
            margin-bottom:13px;
            > span{font-size:60px;color:#6e6e6e;float:left;}
            > h2{float:left;margin-left:15px;color:#fff;font-size:30px;margin-bottom:0;line-height:60px;}
        }
        
        .col-3{
            position:relative;width:33%;margin:0;float:left;display:block;
            span{line-height:20px;padding-right:10px;margin-top:16px;clear:both;float:left;}
            strong{margin-top:15px;color:#fff;font-size:16px;display:block;}
        }

        
        
    }
    .legal {
        background-color:#303030;height:66px;color:#fff;line-height:66px;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;
        .socials{
            margin-left:13px;
            span{height:30px;width:30px;background-color:#fff;color:#303030;line-height:30px;text-align:center;margin-left:4px;font-size:16px;margin-top:19px;}
        }
    }
}

.scroll_down {display:block;position:fixed;bottom:3em;right:2em;width:117px;height:68px;background:url(/img/scroll-hint.png);text-indent:-9999em;opacity:1;transition:opacity 0.2s ease-out;}
.scroll_down.hide {opacity:0;}

@media only screen and (max-width:1023px) {
    body>footer {
        .legal p{font-size:14px;line-height:66px;}
    }
}
@media only screen and (max-width:767px) {
    body>footer {
        .container {
            .col-3 {
                margin-bottom:20px;float:none;width:100%;
                &:first-child {margin-bottom:20px;}
                span {margin-top:0;}
                strong {margin-top:0;}
            }
        }
        .legal{
            text-align:center;line-height:initial;height:auto;padding:24px 0;
            p{float:none;font-size:18px;line-height:24px;padding-right:10px;}
            .pull-right{float:none;}
            .socials{float:none;}
        }
    }
    .scroll_down {display:none;}
}

/*==================================================
 * Tabelstijl
 * ===============================================*/
table{
	width:100%;margin:15px 0 46px 0;border-spacing:0;border-collapse:collapse;
	caption {text-align:left;font-size:22px;font-weight:700;padding:0 11px 0 0;margin-bottom:15px;}
	th,
	td{text-align:left;font-size:14px;padding:8px 10px;}
	tr{
		border-bottom:1px solid #d4d4d4;line-height:29px;
		td:first-child,
		th:first-child{padding-left:0;}
		span{color:$primary-color;}
	}
	th.legend {font-size:30px;font-weight:bold;margin-bottom:30px;padding-bottom:20px;}
	td select {font-size:16px;}
}

table.table-bordered td,
table.table-bordered th {border-bottom:1px solid #d4d4d4;border-right:1px solid #d4d4d4;padding:0 5px;}
table.table-bordered tr>td:first-child,
table.table-bordered tr>th:first-child {border-left:1px solid #d4d4d4;}
table.table-bordered thead {border-top:1px solid #d4d4d4;}

/* ==========================================================================
   Chosen
   ========================================================================== */
.chosen-container{position:relative;display:inline-block;vertical-align:middle;font-size:13px;zoom:1;*display:inline;-webkit-user-select:none;-moz-user-select:none;user-select:none}
.chosen-container *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.chosen-container .chosen-drop{position:absolute;top:100%;left:-9999px;z-index:1010;width:100%;border:1px solid #d4d4d4;border-top:0;background:#fff;box-shadow:0 4px 5px rgba(0,0,0,0.15)}
.chosen-container.chosen-with-drop .chosen-drop{left:0}
.chosen-container a{cursor:pointer}
.chosen-container .search-choice .group-name,.chosen-container .chosen-single .group-name{margin-right:4px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:400;color:#999}
.chosen-container .search-choice .group-name:after,.chosen-container .chosen-single .group-name:after{content:":";padding-left:2px;vertical-align:top}
.chosen-container-single .chosen-single{position:relative;display:block;overflow:hidden;padding:0 0 0 8px;height:25px;border:1px solid #d4d4d4;border-radius:5px;background-color:#fff;background:linear-gradient(top,#fff 20%,#f6f6f6 50%,#eee 52%,#f4f4f4 100%);background-clip:padding-box;box-shadow:0 0 3px #fff inset,0 1px 1px rgba(0,0,0,0.1);color:#444;text-decoration:none;white-space:nowrap;line-height:24px}
.chosen-container-single .chosen-default{color:#999}
.chosen-container-single .chosen-single span{display:block;overflow:hidden;margin-right:26px;text-overflow:ellipsis;white-space:nowrap}
.chosen-container-single .chosen-single-with-deselect span{margin-right:38px}
.chosen-container-single .chosen-single abbr{position:absolute;top:6px;right:26px;display:block;width:12px;height:12px;background:url(/img/chosen-sprite.png) -42px 1px no-repeat;font-size:1px}
.chosen-container-single .chosen-single abbr:hover{background-position:-42px -10px}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover{background-position:-42px -10px}
.chosen-container-single .chosen-single div{position:absolute;top:0;right:0;display:block;width:18px;height:100%}
.chosen-container-single .chosen-single div b{display:block;width:100%;height:100%;background:url(/img/chosen-sprite.png) no-repeat 0 2px}
.chosen-container-single .chosen-search{position:relative;z-index:1010;margin:0;padding:3px 4px;white-space:nowrap}
.chosen-container-single .chosen-search input[type="text"]{margin:1px 0;padding:4px 20px 4px 5px;width:100%;height:auto;outline:0;border:1px solid #d4d4d4;background:#fff url(/img/chosen-sprite.png) no-repeat 100% -20px;background:url(/img/chosen-sprite.png) no-repeat 100% -20px;font-size:1em;font-family:sans-serif;line-height:normal;border-radius:0}
.chosen-container-single .chosen-drop{margin-top:-1px;border-radius:0 0 4px 4px;background-clip:padding-box}
.chosen-container-single.chosen-container-single-nosearch .chosen-search{position:absolute;left:-9999px}
.chosen-container .chosen-results{color:#444;position:relative;overflow-x:hidden;overflow-y:auto;margin:0 4px 4px 0;padding:0 0 0 4px;max-height:240px;-webkit-overflow-scrolling:touch}
.chosen-container .chosen-results li{display:none;margin:0;padding:5px 6px;list-style:none;line-height:15px;word-wrap:break-word;-webkit-touch-callout:none}
.chosen-container .chosen-results li.active-result{display:list-item;cursor:pointer}
.chosen-container .chosen-results li.disabled-result{display:list-item;color:#ccc;cursor:default}
.chosen-container .chosen-results li.highlighted{background-color:#3875d7;background-image:linear-gradient(#3875d7 20%,#2a62bc 90%);color:#fff}
.chosen-container .chosen-results li.no-results{color:#777;display:list-item;background:#f4f4f4}
.chosen-container .chosen-results li.group-result{display:list-item;font-weight:700;cursor:default}
.chosen-container .chosen-results li.group-option{padding-left:15px}
.chosen-container .chosen-results li em{font-style:normal;text-decoration:underline}
.chosen-container-multi .chosen-choices{position:relative;overflow:hidden;margin:0;padding:0 5px;width:100%;height:auto!important;height:1%;border:1px solid #d4d4d4;background-color:#fff;cursor:text}
.chosen-container-multi .chosen-choices li{float:left;list-style:none}
.chosen-container-multi .chosen-choices li.search-field{margin:0;padding:0;white-space:nowrap}
.chosen-container-multi .chosen-choices li.search-field input[type="text"]{margin:1px 0;padding:0;height:25px;outline:0;border:0!important;background:transparent!important;box-shadow:none;color:#000;line-height:normal;border-radius:0}
.chosen-container-multi .chosen-choices li.search-choice{position:relative;margin:3px 5px 3px 0;padding:3px 20px 3px 5px;border:1px solid #d4d4d4;max-width:100%;border-radius:3px;background-color:#eee;background-image:linear-gradient(#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);background-size:100% 19px;background-repeat:repeat-x;background-clip:padding-box;box-shadow:0 0 2px #fff inset,0 1px 0 rgba(0,0,0,0.05);color:#333;line-height:13px;cursor:default}
.chosen-container-multi .chosen-choices li.search-choice span{word-wrap:break-word}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close{position:absolute;top:4px;right:3px;display:block;width:12px;height:12px;background:url(/img/chosen-sprite.png) -42px 1px no-repeat;font-size:1px}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover{background-position:-42px -10px}
.chosen-container-multi .chosen-choices li.search-choice-disabled{padding-right:5px;border:1px solid #ccc;background-color:#e4e4e4;background-image:linear-gradient(top,#f4f4f4 20%,#f0f0f0 50%,#e8e8e8 52%,#eee 100%);color:#666}
.chosen-container-multi .chosen-choices li.search-choice-focus{background:#d4d4d4}
.chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close{background-position:-42px -10px}
.chosen-container-multi .chosen-results{margin:0;padding:0}
.chosen-container-multi .chosen-drop .result-selected{display:list-item;color:#ccc;cursor:default}
.chosen-container-active .chosen-single{border:1px solid #5897fb;box-shadow:0 0 5px rgba(0,0,0,0.3)}
.chosen-container-active.chosen-with-drop .chosen-single{border:1px solid #d4d4d4;-moz-border-radius-bottomright:0;border-bottom-right-radius:0;-moz-border-radius-bottomleft:0;border-bottom-left-radius:0;background-image:linear-gradient(#eee 20%,#fff 80%);box-shadow:0 1px 0 #fff inset}
.chosen-container-active.chosen-with-drop .chosen-single div{border-left:none;background:transparent}
.chosen-container-active.chosen-with-drop .chosen-single div b{background-position:-18px 2px}
.chosen-container-active .chosen-choices{border:1px solid #5897fb;box-shadow:0 0 5px rgba(0,0,0,0.3)}
.chosen-container-active .chosen-choices li.search-field input[type="text"]{color:#222!important}
.chosen-disabled{opacity:.5!important;cursor:default}
.chosen-disabled .chosen-single{cursor:default}
.chosen-disabled .chosen-choices .search-choice .search-choice-close{cursor:default}
.chosen-rtl{text-align:right}
.chosen-rtl .chosen-single{overflow:visible;padding:0 8px 0 0}
.chosen-rtl .chosen-single span{margin-right:0;margin-left:26px;direction:rtl}
.chosen-rtl .chosen-single-with-deselect span{margin-left:38px}
.chosen-rtl .chosen-single div{right:auto;left:3px}
.chosen-rtl .chosen-single abbr{right:auto;left:26px}
.chosen-rtl .chosen-choices li{float:right}
.chosen-rtl .chosen-choices li.search-field input[type="text"]{direction:rtl}
.chosen-rtl .chosen-choices li.search-choice{margin:3px 5px 3px 0;padding:3px 5px 3px 19px}
.chosen-rtl .chosen-choices li.search-choice .search-choice-close{right:auto;left:4px}
.chosen-rtl.chosen-container-single-nosearch .chosen-search,.chosen-rtl .chosen-drop{left:9999px}
.chosen-rtl.chosen-container-single .chosen-results{margin:0 0 4px 4px;padding:0 4px 0 0}
.chosen-rtl .chosen-results li.group-option{padding-right:15px;padding-left:0}
.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div{border-right:none}
.chosen-rtl .chosen-search input[type="text"]{padding:4px 5px 4px 20px;background:#fff url(/img/chosen-sprite.png) no-repeat -30px -20px;background:url(/img/chosen-sprite.png) no-repeat -30px -20px;direction:rtl}
.chosen-rtl.chosen-container-single .chosen-single div b{background-position:6px 2px}
.chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b{background-position:-12px 2px}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),only screen and (min-resolution: 144dpi),only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],.chosen-container-single .chosen-single abbr,.chosen-container-single .chosen-single div b,.chosen-container-single .chosen-search input[type="text"],.chosen-container-multi .chosen-choices .search-choice .search-choice-close,.chosen-container .chosen-results-scroll-down span,.chosen-container .chosen-results-scroll-up span{background-image:url(/img/chosen-sprite@2x.png)!important;background-size:52px 37px!important;background-repeat:no-repeat!important}
}
/* ==========================================================================
   Multiple select
   ========================================================================== */
.ms-parent{display:inline-block;position:relative;vertical-align:middle;padding:0;border:1px solid #d4d4d4;color:#000;background-color:#fff;}
.ms-choice{display:block;padding:0;overflow:hidden;cursor:pointer;border:0;white-space:nowrap;text-decoration:none;min-height:29px;line-height:29px;width:100%;height:100%;color:#000;text-align:left;background-color:transparent;}
.ms-choice.disabled{background-color:#f4f4f4;background-image:none;border:1px solid #ddd;cursor:default}
.ms-choice>span{position:absolute;top:0;left:0;right:20px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;padding-left:8px}
.ms-choice>span.placeholder{color:#999}
.ms-choice>div{position:absolute;top:1px;right:0;width:20px;height:29px;background:url('/img/admin/multiple-select/multiple-select.png') left top no-repeat}
.ms-choice>div.open{background:url('/img/admin/multiple-select/multiple-select.png') right top no-repeat}
.ms-drop{width:100%;overflow:hidden;display:none;margin-top:-1px;padding:0;position:absolute;z-index:1000;background:#fff;color:#000;border:1px solid #aaa;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px}
.ms-drop.bottom{top:100%;-webkit-box-shadow:0 4px 5px rgba(0,0,0,.15);-moz-box-shadow:0 4px 5px rgba(0,0,0,.15);box-shadow:0 4px 5px rgba(0,0,0,.15)}
.ms-drop.top{bottom:100%;-webkit-box-shadow:0 -4px 5px rgba(0,0,0,.15);-moz-box-shadow:0 -4px 5px rgba(0,0,0,.15);box-shadow:0 -4px 5px rgba(0,0,0,.15)}
.ms-search{display:inline-block;margin:0;min-height:26px;padding:4px;position:relative;white-space:nowrap;width:100%;z-index:10000}
.ms-search input{width:100%;height:auto !important;min-height:24px;padding:0 20px 0 5px;margin:0;outline:0;font-family:sans-serif;font-size:1em;border:1px solid #aaa;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-box-shadow:none;-moz-box-shadow:none;box-shadow:none;background:#fff url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px;background:url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px,-webkit-gradient(linear,left bottom,left top,color-stop(0.85,white),color-stop(0.99,#eee));background:url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px,-webkit-linear-gradient(center bottom,white 85%,#eee 99%);background:url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px,-moz-linear-gradient(center bottom,white 85%,#eee 99%);background:url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px,-o-linear-gradient(bottom,white 85%,#eee 99%);background:url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px,-ms-linear-gradient(top,#fff 85%,#eee 99%);background:url('/img/admin/multiple-select/multiple-select.png') no-repeat 100% -22px,linear-gradient(top,#fff 85%,#eee 99%)}
.ms-search,.ms-search input{-webkit-box-sizing:border-box;-khtml-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box}
.ms-drop ul{overflow:auto;margin:0;padding:5px 8px}
.ms-drop ul>li{list-style:none;display:list-item;background-image:none;position:static;padding-left:20px;}
.ms-drop ul>li .disabled{opacity:.35;filter:Alpha(Opacity=35)}
.ms-drop ul>li.multiple{display:block;float:left}
.ms-drop ul>li.group{clear:both}
.ms-drop ul>li.multiple label{width:100%;display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
.ms-drop ul>li label{font-weight:normal;display:block;white-space:nowrap;float:none;width:auto;line-height:22px;}
.ms-drop ul>li label.optgroup{font-weight:bold}
.ms-drop input[type="checkbox"]{vertical-align:middle;float:left;margin-left:-20px;position:relative;top:2px;}
.ms-drop .ms-no-results{display:none}
.ms-drop .ms-select-all {position:relative;border-bottom:1px solid #d5d5d5;padding-bottom:5px;margin-bottom:5px;}
.ms-drop .ms-select-all:after {content:"of:";position:absolute;bottom:-7px;left:0;background-color:#fff;right:0;margin:0 auto;font-style:italic;width:50px;text-align:center;font-size:80%;}
.ms-drop .ms-select-all label {font-weight:bold;}
/*==================================================
 * Formulierstijl
 * ===============================================*/
form, .form {
    margin-bottom:19px;
    &.form-bordered {padding:22px 22px 26px 24px;border:1px solid #d4d4d4;}
    .form-row{
        @extend .clearfix;
        font-family: 'Lato', sans-serif;line-height:29px;font-size:16px;color:#000;margin:16px 0;
        label{width:226px;float:left;line-height:33px;font-weight:700;font-size:14px;padding-right:5px;box-sizing:border-box;}
        &.without-label {padding-left:226px;}
        &.large label {width:100%;float:none;}
        label.error{width:calc(100% - 226px);color:#c7254e;background:#f9f2f4;line-height:24px;display:block;padding:5px 10px;font-weight:normal;margin-top:5px;margin-left:226px;}
        
        input:not([type="checkbox"]):not([type='radio']), 
        textarea, 
        select,
        .datetime-container,
        .note-editor,
        .chosen-container,
        .ms-parent {width:calc(100% - 226px);float:left;box-sizing:border-box;line-height:29px;font-size:14px;padding:0 10px;min-height:29px;}
        select{height:31px;background-color:#fff;border-radius:0;box-shadow:none;}
        select[multiple] {height:auto;}
        select[data-toggle="chosen"],
        .chosen-container,
        .ms-parent {padding:0;}
        input:disabled{position:relative;border:none;color:inherit;font-size:16px;background:none;}
        .note-editor {padding:0;}
        &.large input:not([type="checkbox"]):not([type='radio']), 
            &.large textarea, 
            &.large select,
            &.large .datetime-container {width:100%;float:none;}

        .styled-select.disabled{
            display:inline-block;margin-left:-7px;
            > select{position:relative;border:none;color:inherit;font-size:16px;background:none;padding:0;}
        }
        > .styled-select.disabled:after{content:"";position:relative;z-index:20;background-color:#fff;width:10px;height:20px;margin-left:-19px;display:inline-block;vertical-align:middle;}
        .input-items{
            float:left;width:calc(100% - 226px);min-width:335px;
            br {clear:both;}
        }

        .datetime-container {
            @extend .clearfix;padding:0;
            input:first-child {width:55%;float:left;}
            input:last-child {width:40%;float:right;}
        }

        &.date-fields {
            .input-items {
                input {width:100%;margin-bottom:10px;}
            }
        }
    }
    .opvang_days {
        label {width:auto;margin-left:15px;}
        label:first-child {margin-left:0;}
    }
    .submit-row {
        padding-left:226px;margin: 16px 0;
    }
    .form-data {
        @extend .clearfix;
        .label,
        .value {display:block;float:left;line-height:33px;font-size:14px;}
        .label {width:226px;padding-right:5px;box-sizing:border-box;font-weight:700;font-size:14px;}
        .value {width:calc(100% - 226px);}
    }
}

label.checkbox {
    input[type="checkbox"] {min-height:auto;margin:0;margin-right:5px;}
}
label.radio {
    input[type="radio"] {min-height:auto;margin:0;margin-right:5px;}
}

.contact-form {
    textarea {width:100%;display:block;}
    button {float:right;margin-top:10px;}
}

.note-editor {
    button .fa,
    .button .fa {color:#000;}
}

@media only screen and (max-width:600px) {
    form{
        .form-row{
            label{width:145px;}
            input:not([type="checkbox"]):not([type='radio']), 
            textarea, 
            select,
            .datetime-container,
            .note-editor {width:calc(100% - 145px);}
        }
        .submit-row {
            padding-left:0;
        }
    }
}

/*==================================================
 * Text banner
 * ===============================================*/
#text-banner nav.block-nav{position:relative;z-index:10;margin-top:-96px;}
#main-wrapper #text-banner .excerpt-lg{margin:57px auto 66px auto;}
#main-wrapper .excerpt-wrapper{padding:40px 0;margin-bottom:40px;}
@media only screen and (max-width:767px) {
    #text-banner nav.block-nav{margin-top:24px;}
    #text-banner nav.block-nav .col-3 {width:100%;margin:24px auto 0;float:none;}
    #text-banner nav.block-nav .col-3:first-child {margin:0 auto;}
}

/*==================================================
 * Login
 * ===============================================*/
#login {
    width:700px;margin:0 auto;margin-top:10%;
    img {margin-bottom:50px}
    h1 {font-size:20px;margin:0 0 15px}
    p {margin:0 0 10px;}
    form {
        background:#fff;
        .form-row > label {width:30%;float:left;}
        .form-row > label.error {width:70%;margin-left:30%;float:none;clear:both;}
        .form-row > .twitter-typeahead,
        .form-row > select,
        .form-row > input {width:70%;float:left;}
        .form-row > .twitter-typeahead {
            display:block;
            input[type="text"] {width:100%;}
        }
        button[type="submit"] {margin-left:30%;}
        .forgot-password {font-size:14px;padding-left:20px;}
    }
}

@media only screen and (max-width:767px) {
    #login {
        width:90%;
        form {
            .form-row {
                > label {float:none;width:100%;}
                > input,
                > select,
                > .twitter-typeahead {float:none;width:100%;}
            }
            button[type="submit"] {margin-left:0;}
        }
    }
}

.successmessage {color:$primary-color}
.errormessage {color:$primary-color;}

.error_message {
    color:#f15a3b;
    ul {
        margin:0;padding:0;list-style-position:inside;font-size:14px;
        li {margin-bottom:10px;}
    }
}
/*==================================================
 * News / events
 * ===============================================*/
.grey-bg{background:#ebebeb;}
/*.nieuws-detail,
.nieuws,
.vacatures,
.vacature{min-height:200px;background:#ebebeb;padding-bottom:32px;}*/

header.block-header {
    margin-top:36px;margin-bottom:14px;
    > span{font-size:60px;color:#dbdbdb;float:left;}
    > h2{float:left;margin-left:15px;font-size:30px;line-height:60px;}
}

.all-news > a{color:#000;}

.news, .events, .albums, .documents {
    margin-bottom:17px;margin-top:36px;
    > .excerpt-lg{margin:4px auto 49px auto;}
    article{
        margin-top:36px;position:relative;width:100%;height:180px;background:#fff;background:linear-gradient(-135deg, transparent 20px, #fff 20px, #fff);box-sizing:border-box;
        .content{
            position:relative;padding:24px 30px 23px 203px;height:180px;box-sizing:border-box;
            .overflow {height:117px;overflow:hidden;}
        }
        &.no-image .content {padding-left:30px;}
        h3 {
            a {color: #f15a3b;
                span.read {color: #000;}
            }
            em {color:#f15a3b;font-style:normal;}
        }
        img{position:absolute;top:0;bottom:0;left:0;width:180px;height:auto;margin:auto;overflow:hidden;}
        .date-icon {position:absolute;top:24px;left:39px;}
        >.content>a.button,
        >.content>.buttons {
            position:absolute;right:0;bottom:0;border:none;
            > * {display:block;float:left;}
        }
        &:first-child{margin-top:0px;}

        .meta {font-size:14px;line-height:20px;margin-bottom:0;}
    
        .category{
            position:absolute;bottom:12px;left:203px;font-size:12px;white-space:nowrap;
            time{float:left;font-weight:700;padding-right:4px;}
            p {float:left;margin:0;font-weight:400;font-size:12px!important;max-width:490px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
            a {color:#000;display:inline-block;}
        }
        &.no-image .category {left:30px;}
    }
    .col-1 .category{float:none;display:block;}
    p, .vacancy p{font-size:16px;line-height:22px;}
    h3, .vacancy h3{line-height:29px;font-size:20px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
}

.event {
    .date-icon {float:left;margin:0 20px 20px 0;}
    .description {min-height:66px;}
    ol.media {list-style:none;padding:0;margin:0;}
    ol.media li {display:inline-block;}
    ol.media li a {display:block;}
}

.date-icon {
    text-align:center;width:100px;border:1px solid #d4d4d4;padding-bottom:10px;
    > * {display:block;}
    .month {background-color:$primary-color;color:#fff;padding:2px 0 4px;}
    .day {font-size:30px;line-height:42px;padding-top:5px;}
    .dayofweek {color:$primary-color;}
}
.cancelled .date-icon {
    .month {background-color:#BFBFBF}
    .dayofweek {color:#BFBFBF}
}

.news-summary {
    background-color:#fff;padding:24px 30px;
}

.event .category,
.news-summary .category {
    font-size:14px;margin-top:20px;margin-bottom:23px;
    p {display:inline;font-size:14px;}
}

ul.pagination {
    list-style:none;margin:0;padding:0;
    li {display:inline-block;margin-left:1px;}
    li a,
    li span {color:#000;line-height:36px;padding:8px 15px;background-color:#fff;}
    li a span {line-height:normal;padding:0;}
    li.active span {color:#fff;background-color:$primary-color;}
}

@media only screen and (max-width:767px) {
    .news, .events, .albums, .documents {
        article{height:inherit;}
        article h3{text-overflow:initial;white-space:inherit;}
        article img{position:relative;width:100%;height:auto;display:block;box-sizing:border-box;padding:5px 40px;}
        article .date-icon {position:relative;top:20px;left:20px;margin-bottom:20px;}
        article .content{padding:20px 20px 75px 20px;height:inherit;}
        article .content > .overflow{height:auto;}
        article .content > p{font-size:18px;line-height:24px;}
        article .category{position:static;}
        article .title-box{margin-bottom:10px;}
    }
}

/*==================================================
 * Zoeken
 * ===============================================*/
ul.searchresults {
    list-style:none;padding:0;
    li {
        margin-bottom:23px;
        a {display:block;color:$primary-color;}
        .result {font-weight:bold;}
    }
}
section .search {margin-bottom:23px;}
section .search input {display:block;float:left;-webkit-box-sizing:border-box;box-sizing:border-box;width:80%;line-height:38px;padding:0 10px;border:1px solid #d4d4d4;border-right:0;}
section .search button {display:block;float:right;-webkit-box-sizing:border-box;box-sizing:border-box;width:20%;}
/*==================================================
 * Tabs
 * ===============================================*/
.nav-tabs{width:100%;padding:0;margin-bottom:0;margin-top:0;}
.nav-tabs li{position:relative;display:block;list-style:none;float:left;}
.nav-tabs li.active > a{background-color:transparent;border:1px solid #d4d4d4;border-bottom-color:#f8f8f8;}
.nav-tabs li.disabled > a{position:relative;z-index:-1;color:#ccc;}
.nav-tabs li > a{outline:0;position:relative;display:block;padding:10px 16px;line-height:1.5;border:1px solid transparent;border-radius:2px 2px 0 0;text-decoration:none;color:#000;}
.tabs-content{
    border-top:1px solid #d4d4d4;margin:-1px 0 0 0;padding:28px 0;
    .inner{
        display:none;
        &.active{display:block;}
        button{width:100%;}
        h4.title .capitalize{text-transform:capitalize;}

        .submit-row button {width:auto;}
    }
}

/*==================================================
 * Typehint
 * ===============================================*/
.tt-query {box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);}
.tt-hint {color: #999}
.tt-menu {min-width:100%;white-space:nowrap;padding: 8px 0;background-color:#fff;border:1px solid #d4d4d4}
.tt-suggestion {padding: 3px 11px;line-height:20px;}
.tt-suggestion:hover {cursor: pointer;color: #fff;background-color: $primary-color;}
.tt-suggestion.tt-cursor {color: #fff;background-color: #0097cf;}
.tt-suggestion p {margin: 0;}
.tt-input {background:#fff!important;}

/*==================================================
 * Helpers
 * ===============================================*/
.clearfix:before,
.clearfix:after {content:" ";display:table;}
.clearfix:after {clear:both;}
.clearfix {*zoom:1;}
.loading {position:relative;}
.loading:after {content:"";position:absolute;top:0;left:0;width:100%;height:100%;background:rgba(255,255,255,0.7) url(/img/ajax-loader.svg) no-repeat center center/20px 20px;}

/*==================================================
 * Slider
 * ===============================================*/    

#slider {
	position:relative;height:60vh;
	ol {
		list-style:none;margin:0;padding:0;
		li {
			position:absolute;top:0;left:0;opacity:0;transition:opacity 0.5s ease-in-out;width:100%;height:60vh;background-position:center center;background-size:cover;background-repeat:no-repeat;
			&.active {opacity:1;}
		}
	}

    .call-to-action {
        position:absolute;right:0;left:0;margin:auto;bottom:180px;z-index:10;width:600px;height:85px;background:rgba(255,255,255,0.8);padding:0 30px;box-sizing:border-box;
        .content{float:left;line-height:84px;font-size:20px;}
        h2{display:inline;}
        span{font-weight:700;}
        .button,
        button{float:right;margin-top:25px;}
    }

    @media only screen and (max-width:767px) {
        .call-to-action {display:none;}
    }
}

/*==================================================
 * Filter op item lijst pagina
 * ===============================================*/

.filters{margin:auto;text-align:center;}
#faq .filters {margin-bottom:36px;}
.filters p,
.filters label{display:inline;margin:0 15px 0 0;padding:0;vertical-align:middle;}

/*==================================================
 * Testimonials
 * ===============================================*/
ul.testimonials {
    margin:0;padding:50px 0;
    li {
        @extend .clearfix;list-style:none;margin-bottom:36px;
        img {float:left;display:block;}
        .testimonial {
            position:relative;padding:28px;margin-left:227px;height:180px;background:#fff;box-sizing:border-box;
            &:before {content:"";border-top:29px solid transparent;border-bottom:29px solid transparent;width:0px;height:0px;position:absolute;top:0;bottom:0;margin:auto;left:-29px;border-right:29px solid #fff;}
            > p {font-size:20px;line-height:28px;font-weight:300;}
            footer {position:absolute;bottom:12px;left:0;width:100%;}
            .info{font-size:14px;line-height:40px;margin-left:28px;padding:0;}
            .info span{font-weight:700;padding-right:5px;margin-right:5px;border-right:1px solid #000;}
            .button {float:right;margin-right:28px;}
        }
        &:nth-child(even) {
            img {float:right;}
            .testimonial {margin-left:0;margin-right:227px;}
            .testimonial:before{left:auto;right:-29px;border-left:29px solid #fff;border-right:0;}
        }
    }
}

@media only screen and (max-width:767px) {
    ul.testimonials {
        li,
        li:nth-child(even) {
            img {width:100%;height:auto;float:none;}
            .testimonial {
                width:100%;margin-left:0;margin-right:0;margin-top:48px;height:auto;
                footer {position:static;}
                .info {float:none;margin:0!important;}
                .button{float:none;margin:0!important;}
            }
            .testimonial:before {border-top:0;border-bottom:29px solid #fff;border-left:29px solid transparent;border-right:29px solid transparent;top:-29px;bottom:initial;left:0;right:0;}
        }
    }
}

/*==================================================
 * Campaigns
 * ===============================================*/

ul.campaigns {
    @extend .testimonials;
    li {
        .testimonial {
            margin-left:325px;height:auto;
            &:before {content:none;}
        }
    }
    li:nth-child(even) {
        .testimonial {
            margin-right:325px;
            &:before {content:none;}
        }
    }
}

@media only screen and (max-width:767px) {
    ul.campaigns {
        li,
        li:nth-child(even) {
            .testimonial {margin-left:0;margin-right:0}
        }
    }
}

section.call-to-action > .container{text-align:center;padding:60px;}
section.call-to-action > .container > h2{margin-bottom:21px;}

/*==================================================
 * Veelgestelde vragen
 * ===============================================*/
#faq {
    margin-bottom:60px;margin-top:36px;
    h3 {margin-bottom:19px;}
    > ul{width:100%;margin:0;padding:0;}
    > ul .faq-item {
        width:100%;list-style:none;margin:0 0 8px 0;
        .head {
            background-color:#e9e9e9;border-bottom:2px solid #c3c3c3;padding:10px;line-height:32px;*zoom: 1;cursor:pointer;
            &:before, 
            &:after{content:" ";display:table;}
            &:after{clear:both;}
            &:hover{background-color:#f15a3b;border-color:#b7452e;color:#fff;}
            > span{float:right;font-size:24px;line-height:32px;transform: rotate(0deg);transition:all 0.4s;}
        }
        .content{
            display:none;padding:17px 12px 26px 12px;border:1px solid #d4d4d4;
            p{line-height:22px;font-weight:400;}
        }
    }

    > ul .faq-item.active .content{display:block;}
    > ul .faq-item.active .head{background-color:#f15a3b;border-color:#b7452e;color:#fff;}
    > ul .faq-item.active .head > span{transform: rotate(180deg);}
}

/*==================================================
 * Contact
 * ===============================================*/

.contact aside .strong{font-weight:700;margin-bottom:0;}
.contact aside .contact-details{margin-left:29px;}
.contact aside .contact-details a {color:#000;}
.contact aside .contact-details > h3{font-size:30px;margin:8px 0 25px 0;}
.contact aside .contact-details ul{margin:0;padding:0;}
.contact aside .contact-details ul li{list-style:none;}
.contact aside .contact-details ul li > .head{font-weight:700;margin:0;}
.contact aside .contact-details ul li > .head a,
.contact aside .contact-details ul li .number {color:#000;padding-left:11px;}
.map{overflow:hidden;}
.map #gmap_canvas{width:100%;height:280px;}
.map #gmap_canvas img{max-width:none!important;background:none!important}
@media only screen and (max-width:767px) {
    .contact .container .col-3{margin:0;}
}

.video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}

.lunchtips-container {
    display: flex;
    flex-wrap: wrap;

    a {
        flex: 1 1 225px;
        max-width: 500px;
        box-shadow: 0 8px 22px -2px rgba(0,0,0,0.5);
        margin: 0 50px 50px 0;
        padding: 10px;
        color: white;

        .inner {
            position: relative;
            border: 1px solid silver;

            .title_box {
                position: absolute;
                height: 30%;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 5%;
                box-sizing: border-box;

                .title {
                }

                img {
                    margin-top: 3%;
                }
            }
        }

        &:nth-child(4n+1) {
            .inner .title_box {
                background: #D03C7D;
            }
        }

        &:nth-child(4n+2) {
            .inner .title_box {
                background: #8E3A84;
            }
        }

        &:nth-child(4n+3) {
            .inner .title_box {
                background: #75AA46;
            }
        }

        &:nth-child(4n+0) {
            .inner .title_box {
                background: #4D9BD5;
            }
        }
    }
}

.traktaties-container {
    display: flex;
    flex-wrap: wrap;

    a {
        flex: 1 1 225px;
        max-width: 500px;
        box-shadow: 0 8px 22px -2px rgba(0,0,0,0.5);
        margin: 0 50px 50px 0;
        padding: 10px;
        color: white;

        .inner {
            position: relative;
            border: 1px solid silver;

            img {
                width: 100%;
                border-radius: 50%;
                vertical-align: middle;
                border: 3px solid white;
            }

            .title_box {
                position: absolute;
                inset: 0;
                text-align: center;
                padding-top: 10%;
                box-sizing: border-box;

                .title {
                    padding: 0.3rem;
                }
            }
        }

        &:nth-child(4n+1) {
            .inner, .inner .title {
                background: #D03C7D;
            }
        }

        &:nth-child(4n+2) {
            .inner, .inner .title {
                background: #8E3A84;
            }
        }

        &:nth-child(4n+3) {
            .inner, .inner .title {
                background: #75AA46;
            }
        }

        &:nth-child(4n+0) {
            .inner, .inner .title {
                background: #4D9BD5;
            }
        }
    }
}

ol#lunchtip.album li a img, ol#traktatie.album li a img {
    border: 1px solid #d4d4d4;
    width: 190px;
    height: 190px;
}

#add_new_child {
    span.twitter-typeahead {

        width: calc(100% - 227px);

        @media(max-width: 600px){
            width: calc(100% - 146px);
        }

        input {
            width: 100%;
        }
    }

    .sex {
        label {
            float: left;
            width: 30%;
        }
    }

    .opvang {
        width: 100%;

        label {
            float: left;
            width: 15%;
            margin-left: 0;

            input[type=checkbox] {
                margin-right: 0.5em;
            }
        }
    }

    #middle_name, #voornaam {
        margin-bottom: 10px;
    }

    .tarif, #last_name, #achternaam {
        margin-left: 145px;
    }

    @media (min-width: 600px) {
        .tarif, #last_name, #achternaam {
            margin-left: 226px;
        }
    }

    @media (min-width: 960px) {
        #middle_name, #voornaam {width: 25%; margin-right: 2px; margin-bottom: 0;}
        #last_name, #achternaam {width: 50%; margin-left: 0;}
    }
}