#login-message-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1001;
  cursor: pointer;

  .overlay-container {
    position: relative;
    max-width: 75vw;
    max-height: 90%;
    overflow: auto;
    width: 900px;
    border-left: calc(1rem + 4vw) solid #f15a3b;

    .overlay-container__closer {
      color: black;
      position: absolute;
      right: 6px;
      top: 5px;
    }

    .overlay-container__header {
      text-align: center;
      background: #e5e5e5;
      padding: 1rem 2rem;

      h2 {
        //font-size: 100%;
      }
    }

    .overlay-container__content {
      padding: 2rem;
      background: white;

      img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 1rem auto;
      }
    }
  }
}