@import 'subbrand-header-bootstrap';
@import 'subbrand-header-summernote';

#subheader {
  height:40px;width:100%;background:#e5e5e5;box-shadow: inset 0 -4px 7px -5px rgba(0,0,0,0.7);font-family:'Lato', sans-serif;font-size:14px;color:#5c5b5b;
  a {text-decoration:none;}

  .container {
    float:right;padding:0;max-width:none;
  }

  nav {
    float:right;
    .menu-btn {display:none;}
    ul {margin:0;padding:0;}
    li {list-style:none;line-height:40px;padding-right:20px;display:inline-block;font-weight:700;color:#5c5b5b;}
    li a{color:#484848;}
  }

  .dropdown-wrapper {
    display:none;position:absolute;top:100%;right:0;width:332px;
    &.expand {display:block;z-index:999;}
    > div {display:block;background:#fff;padding:34px 0;}

    .shadow:before {right:20px;bottom:0;}
    .shadow {position:relative;-webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;-moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;}
    .shadow:before, .shadow:after {content:"";position:absolute; z-index:-1;-webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);-moz-box-shadow:0 0 20px rgba(0,0,0,0.8);box-shadow:0 0 20px rgba(0,0,0,0.8);top:10px;bottom:10px;left:0;right:0;-moz-border-radius:100px / 10px;border-radius:100px / 10px;}
    .shadow:after {right:10px;left:auto;-webkit-transform:skew(8deg) rotate(3deg);-moz-transform:skew(8deg) rotate(3deg);-ms-transform:skew(8deg) rotate(3deg);-o-transform:skew(8deg) rotate(3deg);transform:skew(8deg) rotate(3deg);}
    
    ul {margin:0;padding:0;list-style:none}
    li:hover {background:rgba(0,0,0,0.05);}
    li a {text-decoration:none;color:#484848;line-height:67px;display:block;}
    li a img {border:0;}
    li a span {font-weight:700;line-height:25px;font-size:16px;padding-left:12px;}
    li a span span {padding-left:0;padding-right:4px;}
  }

  .login {
    position:relative;cursor:pointer;display:block;background:$primary-color;float:right;height:40px;padding:0 29px;box-sizing:border-box;white-space:nowrap;color:#fff;line-height:40px;
    a {color:#fff;}
    .fa {font-size:18px;}
  }

  .logged-in .login {
    .dropdown-wrapper {
      .shadow {padding:12px 0;}
      li a {line-height:40px;}
    }
  }

  .uitgelogd {
    .fa {display:none;}
  }

  .subsite {
    display:block;cursor:pointer;white-space:nowrap;height:40px;float:right;background:$secundary-color;box-sizing:border-box;
    img{
      vertical-align:middle;
      &.subsite-logo{display:block;float:left;margin:10px 0 0 25px;width:80px;height:auto;}
    }
    .subsite-title{display:block;float:left;white-space:nowrap;font-size:14px;line-height:40px;font-weight:700;color:#fff;padding:0 10px;}
    select{position:absolute;top:20px;right:20px;opacity:0;}
    select:active{opacity:1;}
    span.show-subsites{font-size:18px;line-height:40px;color:#fff;font-weight:400;margin-right:25px;}

    .dropdown-wrapper {
      li {height:67px;padding:0 0 0 29px;}
      li a img {vertical-align:middle;margin-top:16px;}
    }
  }

  .dropdown-login {
    li {
      height:67px;padding:0 0 0 29px;
      img {width:80px;height:auto;vertical-align:middle;}
    }
  }

  .bijdrage{
    float:right;background-color:#d7d7d7;box-sizing:border-box;border:2px solid #cecece;color:#484848;font-weight:700;display:block;padding:0;width:73px;text-align:center;
    &.loading:after {content:none;}
    .fa {font-size:18px;}
    span{line-height:36px;color:#484848;font-weight:700;}
  }
}

#subheader.fixed {
  .subsite {
    position:fixed;top:0;right:0;width:200px;height:57px;line-height:57px;z-index:999;
    .subsite-title {display:none;}
    img.subsite-logo {margin-top:16px;width:auto;}
    span.show-subsites {float:right;line-height:57px;}
  }
  .login {
    position:fixed;top:0;right:200px;height:57px;line-height:57px;z-index:999;width:73px;
    &:hover {box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.2);}
  }
  .uitgelogd {
    .fa {display:inline;}
    .txt {display:none;}
  }
  .bijdrage {
    position:fixed;top:0;right:273px;height:57px;line-height:57px;z-index:999;background-color:transparent;border-color:transparent;
    span {color:#fff;}
  }

}

@media only screen and (max-width:767px) {
  #subheader {
    nav {
      ul {
        display:none;position:absolute;top:100%;left:0;z-index:999;background-color:#e5e5e5;width:100%;
        &.expand {display:block;}
        li {
          display:block;
          a {display:block;padding:0 12px;}
        }
      }
      .menu-btn {display:block;padding:0 30px;font-size:18px;line-height:40px;cursor:pointer;}
    }
    .login {position:static;}
    .login, .subsite {
      .dropdown-wrapper {min-width:100%;}
    }
    .bijdrage {
      margin-right:0!important;
    }
  }
}

@media only screen and (max-width:767px) {
  #subheader {
    .subsite {
      width:calc(100% - 110px - 73px);overflow:hidden;
      .subsite-title {display:none;}
      span.show-subsites {float:right;}
    }
    nav, 
    .bijdrage {width:73px;}
    .login {width:110px;}
  }
  #subheader .logged-in {
    .subsite {width:calc(100% - 73px - 73px - 73px);}
    .login {width:73px;}
  }

  #subheader.fixed {
    .login,
    .subsite,
    .bijdrage {position:static;}
  }
}

@media only screen and (max-width:374px) {
  #subheader {
    .subsite {
      img.subsite-logo {margin:7px 0 0 12px;width:77px;height:auto;}
    }
  }
}
/* ==========================================================================
   Fancybox - add content
   ========================================================================== */

.fancybox-wrap,.fancybox-skin,.fancybox-outer,.fancybox-inner,.fancybox-image,.fancybox-wrap iframe,.fancybox-wrap object,.fancybox-nav,.fancybox-nav span,.fancybox-tmp{padding:0;margin:0;border:0;outline:0;vertical-align:top}
.fancybox-wrap{position:absolute;top:0;left:0;z-index:8020}
.fancybox-skin{position:relative;background:#f9f9f9;color:#444;text-shadow:none;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px}
.fancybox-opened{z-index:8030}
.fancybox-opened .fancybox-skin{-webkit-box-shadow:0 10px 25px rgba(0,0,0,0.5);-moz-box-shadow:0 10px 25px rgba(0,0,0,0.5);box-shadow:0 10px 25px rgba(0,0,0,0.5)}
.fancybox-outer,.fancybox-inner{/*position:relative*/}
.fancybox-inner{overflow:hidden;padding:15px;max-width:100%;box-sizing:border-box;}
.fancybox-type-iframe .fancybox-inner{-webkit-overflow-scrolling:touch}
.fancybox-error{color:#444;font:14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;margin:0;padding:15px;white-space:nowrap}
.fancybox-image,.fancybox-iframe{display:block;width:100%;height:100%}
.fancybox-image{max-width:100%;max-height:100%}
#fancybox-loading,.fancybox-prev span,.fancybox-next span{background-image:url('/img/fancybox/fancybox_sprite.png')}
#fancybox-loading{position:fixed;top:50%;left:50%;margin-top:-22px;margin-left:-22px;background-position:0 -108px;opacity:.8;cursor:pointer;z-index:8060}
#fancybox-loading div{width:44px;height:44px;background:url('/img/fancybox/fancybox_loading.gif') center center no-repeat}
.fancybox-close{position:absolute;top:10px;right:10px;width:36px;height:36px;cursor:pointer;z-index:8040;background:#f9f9f9 url(/img/admin/icon-close.svg);fill:currentColor;color:#fff;}
.fancybox-nav{position:absolute;top:0;width:40%;height:100%;cursor:pointer;text-decoration:none;background:transparent url('/img/fancybox/blank.gif');-webkit-tap-highlight-color:rgba(0,0,0,0);z-index:8040}
.fancybox-prev{left:0}
.fancybox-next{right:0}
.fancybox-nav span{position:absolute;top:50%;width:36px;height:34px;margin-top:-18px;cursor:pointer;z-index:8040;visibility:hidden}
.fancybox-prev span{left:10px;background-position:0 -36px}
.fancybox-next span{right:10px;background-position:0 -72px}
.fancybox-nav:hover span{visibility:visible}
.fancybox-tmp{position:absolute;top:-99999px;left:-99999px;visibility:hidden;max-width:99999px;max-height:99999px;overflow:visible !important}
.fancybox-lock{overflow:hidden !important;width:auto}
.fancybox-lock body{overflow:hidden !important}
.fancybox-lock-test{overflow-y:hidden !important}
.fancybox-overlay{position:absolute;top:0;left:0;overflow:hidden;display:none;z-index:8010;background:url('/img/fancybox/fancybox_overlay.png')}
.fancybox-overlay-fixed{position:fixed;bottom:0;right:0}
.fancybox-lock .fancybox-overlay{overflow:auto;overflow-y:scroll}
.fancybox-title{visibility:hidden;font:normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;position:relative;text-shadow:none;z-index:8050}
.fancybox-opened .fancybox-title{visibility:visible}
.fancybox-title-float-wrap{position:absolute;bottom:0;right:50%;margin-bottom:-35px;z-index:8050;text-align:center}
.fancybox-title-float-wrap .child{display:inline-block;margin-right:-100%;padding:2px 20px;background:transparent;background:rgba(0,0,0,0.8);-webkit-border-radius:15px;-moz-border-radius:15px;border-radius:15px;text-shadow:0 1px 2px #222;color:#FFF;font-weight:bold;line-height:24px;white-space:nowrap}
.fancybox-title-outside-wrap{position:relative;margin-top:10px;color:#fff}
.fancybox-title-inside-wrap{padding-top:10px}
.fancybox-title-over-wrap{position:absolute;bottom:0;left:0;color:#fff;padding:10px;background:#000;background:rgba(0,0,0,.8)}
@media only screen and (-webkit-min-device-pixel-ratio:1.5),only screen and (min--moz-device-pixel-ratio:1.5),only screen and (min-device-pixel-ratio:1.5){
   #fancybox-loading div{background-image:url('/img/fancybox/fancybox_loading@2x.gif');background-size:24px 24px}
}

.fancybox-subbrand {
  .fancybox-inner {padding:0}
  .fancybox-close {background-color:transparent;top:16px;right:16px;}
}

.fancybox-inner .videocontainer {
  width:768px;
  video {max-width:none;}
}

.bijdrage-form{
  box-sizing:border-box;color:#000;height:100%;position:relative;
  &:before {content:"";position:absolute;top:0;left:0;width:120px;height:100%;background:$primary-color;}
  p, ol {font-size:14px;margin:0 0 10px;}
  ol {line-height:22px;}
  .alert {
    padding:20px 30px;margin-left:120px;
    &.alert-danger {color:#a94442;}
    ul {
      list-style-position:inside;padding:0;margin:0;
      li {padding:2px 0;}
    }
  }
  header {
    background:#e5e5e5;padding:20px 30px 20px 150px;
    p {margin:0;padding-bottom:10px}
  }
  section {
    border-left:120px solid $primary-color;
    aside {
      width:120px;height:100%;margin:0;padding:0;margin-left:-120px;text-align:center;position:relative;z-index:2;
      .fa {font-size:40px;line-height:82px;color:#e5e5e5;background-color:$primary-color;}
    }
    .form {
      width:100%;height:100%;float:right;position:relative;z-index:1;
      &:after {content:"";position:absolute;top:0;left:-60px;border-left:1px solid #e5e5e5;height:100%;}
      .form-row {
        padding:0 30px;
        .input-items ol {margin-left:-26px}
        .upload-preview input {width:100%!important;}
      }
      > p {padding:30px;}
    }
    &.no-border {
      .form:after {border-left:0;}
    }
  }
  ol.choose {
    margin:0;padding:0;
    > li {
      display:block;position:relative;border-bottom:1px solid #ebebeb;
      &:hover {background-color:#ebebeb;}
      &:first-child {border-top:1px solid #ebebeb;}
      a {
        position:relative;display:block;color:#444;line-height:40px;padding:20px 0 20px 30px;font-size:18px;
        .plus {width:31px;height:31px;display:block;float:left;margin-right:30px;padding-top:8px;text-indent:-999em;background:url(/img/icon-plus.png) no-repeat center center;}
        .fa{font-size:18px;padding-right:10px;}   
      }
    }
  }
  footer {
    border-left:120px solid $primary-color;padding:20px 30px 0;
    >div:first-child {border-top:1px solid #e5e5e5;padding-top:20px;}
    .message {height:45px;display:block;}
    .message.error {color:#c7254e;}
    .progress {
      margin-top:20px;margin-bottom:0;
    }
  }
  form{
    border:none;padding:0;margin:0;
    .form-row {
      > label{
        width:25%;min-height:1px;
        &.error {font-size:14px;color:#c7254e;background:#f9f2f4;display:block!important;padding:5px 10px;margin:0 0 0 30%;width:70%;}
      }
      input:not([type="checkbox"]):not([type='radio']),
      textarea,
      select:not(.cms_dropdown),
      .note-editor,
      .chosen-container,
      .input-items {width:70%;box-sizing:border-box;font-size:14px;font-family:Lato, sans-serif}
      .input-items {
        input:not([type="checkbox"]):not([type='radio']),
        textarea,
        select {width:100%;}
      }
      .note-toolbar {margin-bottom:5px;}
      textarea {height:100px;}
      .input-datetime input:first-child {display:block;float:left;width:60%;}
      .input-datetime input:last-child {display:block;float:right;width:30%;}
      .ms-parent {
        .ms-search {
          input {width:100%;}
        }
        .ms-drop .ms-select-all:after {bottom:-13px}
        .ms-drop ul > li label {line-height:22px;float:none;width:auto;font-weight:400;padding-right:0;}
      }
      &.form-row-recurring, &.form-row-recurring-ondays {
        .label {display:inline;padding:0 10px;}
        .label:first-child {padding-left:0;}
        .label.float {float:left;}
        label.error {margin-left:0;width:100%;}
        select {display:inline;float:none;width:auto;}
        select[multiple] {width:175px;}
        .ms-parent {
          display:inline-block;float:none;
        }
        .ms-drop .ms-select-all {
          padding-bottom:20px;
          margin-bottom:20px;
        }
        .recurring_until {
          display:block;padding-left:59px;
          label, 
          input {
            display:inline-block;float:none;width:auto;
            &[type="number"] {width:60px;}
          }
          label em {
            display:block;white-space:normal;font-size:12px;line-height:18px;font-weight:normal;font-style:normal;
          }
        }
        .input-items {white-space:nowrap;width:100%;}
      }
      &.date-fields .input-items input:not([type="checkbox"]):not([type='radio']) {margin-bottom:10px;}
      /*&.date-fields .input-items input:not([type="checkbox"]):not([type='radio']) {
        width:49%;
        &:first-child {margin-right:2%;}
      }*/
    }
    a.button{float:left;}
    button{float:right;cursor:pointer;}
    button > span, 
    a.button {margin-right:10px;}
    ol.media-preview {
      list-style:none;padding:0;margin:0;
      > li {
        img,
        span {display:block;float:left;}
        img {width:50px;height:50px;}
        span {line-height:50px;margin-left:15px;}
      }
    }
  }
}

@media only screen and (max-width:767px) {
  .bijdrage-form {
    &:before {content:none!important;}
    header,
    footer {
      padding-left:30px;border-left:0;
    }
    section {
      border-left:0;
      aside {
        display:none;
      }
    }
    ol.choose {
      > li {
        &:first-child {border-top:0;}
      }
    }
    form, .form {
      margin-bottom:0;
      .form-row {
        > label {display:block;width:100px;float:none;}
        input:not([type="checkbox"]):not([type='radio']),
        textarea,
        select:not(.cms_dropdown),
        .note-editor,
        .chosen-container,
        .input-items {width:100%;float:none;}
        .input-items {min-width:0;}
      }
    }
  }
}


/* ==========================================================================
   Wysihtml5
   ========================================================================== */
ul.wysihtml5-toolbar {
  margin: 0;
  padding: 0;
  display: block;
}

ul.wysihtml5-toolbar::after {
  clear: both;
  display: table;
  content: "";
}

ul.wysihtml5-toolbar > li {
  float: left;
  display: list-item;
  list-style: none;
  margin: 0 5px 10px 0;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=bold] {
  font-weight: bold;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=italic] {
  font-style: italic;
}

ul.wysihtml5-toolbar a[data-wysihtml5-command=underline] {
  text-decoration: underline;
}

ul.wysihtml5-toolbar a.btn.wysihtml5-command-active {
  background-image: none;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),0 1px 2px rgba(0, 0, 0, 0.05);
  background-color: #E6E6E6;
  background-color: #D9D9D9;
  outline: 0;
}

ul.wysihtml5-commands-disabled .dropdown-menu {
  display: none !important;
}

ul.wysihtml5-toolbar div.wysihtml5-colors {
  display:block;
  width: 50px;
  height: 20px;
  margin-top: 2px;
  margin-left: 5px;
  position: absolute;
  pointer-events: none;
}

ul.wysihtml5-toolbar a.wysihtml5-colors-title {
  padding-left: 70px;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="black"] {
  background: black !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="silver"] {
  background: silver !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="gray"] {
  background: gray !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="maroon"] {
  background: maroon !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="red"] {
  background: red !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="purple"] {
  background: purple !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="green"] {
  background: green !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="olive"] {
  background: olive !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="navy"] {
  background: navy !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="blue"] {
  background: blue !important;
}

ul.wysihtml5-toolbar div[data-wysihtml5-command-value="orange"] {
  background: orange !important;
}

ul.wysihtml5-toolbar {
  .btn{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;vertical-align:middle;cursor:pointer;background-image:none;border:1px solid transparent;white-space:nowrap;padding:6px 12px;font-size:14px;line-height:1.428571429;border-radius:4px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none}
  .btn:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px}
  .btn:hover,
  .btn:focus{color:#333;text-decoration:none}
  .btn:active,
  .btn.active{outline:0;background-image:none;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);box-shadow:inset 0 3px 5px rgba(0,0,0,.125)}
  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn{cursor:not-allowed;pointer-events:none;opacity:.65;filter:alpha(opacity=65);-webkit-box-shadow:none;box-shadow:none}
  .btn-default{color:#333;background-color:#fff;border-color:#ccc}
  .btn-default:hover,
  .btn-default:focus,
  .btn-default:active,
  .btn-default.active,
  .open .dropdown-toggle.btn-default{color:#333;background-color:#ebebeb;border-color:#adadad}
  .btn-default:active,
  .btn-default.active,
  .open .dropdown-toggle.btn-default{background-image:none}
  .btn-default.disabled,
  .btn-default[disabled],
  fieldset[disabled] .btn-default,
  .btn-default.disabled:hover,
  .btn-default[disabled]:hover,
  fieldset[disabled] .btn-default:hover,
  .btn-default.disabled:focus,
  .btn-default[disabled]:focus,
  fieldset[disabled] .btn-default:focus,
  .btn-default.disabled:active,
  .btn-default[disabled]:active,
  fieldset[disabled] .btn-default:active,
  .btn-default.disabled.active,
  .btn-default[disabled].active,
  fieldset[disabled] .btn-default.active{background-color:#fff;border-color:#ccc}

  .btn-group{position:relative;display:inline-block;vertical-align:middle;}
  .btn-group>.btn{position:relative;float:left;}
  .btn-group>.btn:first-child{margin-left:0;}
  .btn-group>.btn:not(:last-child):not(.dropdown-toggle){border-bottom-right-radius:0;border-top-right-radius:0;}
  .btn-group>.btn:not(:first-child){border-bottom-left-radius:0;border-top-left-radius:0;border-left-color:transparent;}

  .btn > span{line-height:20px;}
}